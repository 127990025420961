<template>
    <div class="modal fade" :id="inputId" tabindex="-1" data-bs-backdrop="static" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div v-if="value && value.works" class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" :id="inputId + 'Label'">
                    Unesi radove
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    

  
                    <a v-for="work in value.works" :key="work.id" class="list-group-item list-group-item-action" aria-current="true">
                        <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                                <span class="badge bg-info">{{getLocationFullindex(work.work_location)}}</span>
                                <span class="ms-1" v-for="(name, index) in fullNameList(work.work_location)" :key="index">
                                    <span :class="{'badge':true, 'bg-secondary':true }">
                                        {{name}}
                                    </span>
                                    ❯
                                </span>
                                {{work.work_location.name}}
                            </h5>
                            <div v-if="work.finished_units >= 1" class="status-box text-end">
                                <!-- <input type="radio" class="btn-check" v-model="work.work_status" value="IN_PROGRESS" @change="resetStatus" :name="'work-status-' + work.id" :id="'not-started-' + work.id" autocomplete="off" checked> -->
                                <!-- <label class="btn btn-outline-secondary" :for="'not-started-' + work.id">/</label> -->

                                <input type="radio" class="btn-check" v-model="work.work_status" value="APPROVED" :name="'done-' + work.id" :id="'done-' + work.id" autocomplete="off" @change="changeApprove">
                                <label class="btn btn-outline-success" :for="'done-' + work.id">Korektno urađeno</label>
                                
                                <input type="radio" class="btn-check" v-model="work.work_status" value="REJECTED" :name="'not-done-' + work.id" :id="'not-done-' + work.id" autocomplete="off"  @change="changeRejected">
                                <label class="btn btn-outline-danger" :for="'not-done-' + work.id">Nije korektno</label>

                                

                                <template v-if="work.finished_units == '' || work.finished_units > 0">
                                    <div class="input-group mb-3 partialy-input-group">
                                        <input type="number" :id="'done-units-' + work.id" v-model="work.approved_units" class="form-control approved-units-input" aria-label="Kolicina posla" min="1" :max="work.normative_value" @change="changeNorm">
                                        <span class="input-group-text">{{value.work_position.position_unit.norm_unit}} / <template v-if="work.normative_value">{{work.normative_value}}</template><template v-else>Nije normirano</template> {{value.work_position.position_unit.norm_unit}}
                                            <small v-if="work.finished_units != work.approved_units" class="ms-3"> (Prijavljeno {{work.finished_units}} {{value.work_position.position_unit.norm_unit}})</small>
                                        </span>

                                    </div>
                                </template>

                                <select class="form-select" v-model=work.finished :disabled="work.normative_value" @change="resetStatus">
                                    <option :value="false">Ostalo još</option>
                                    <option :value="true">Završeno sve</option>
                                </select>

                            </div>
                            <div v-else>
                                <template v-if="isWorkNotReadyStatusUser(work)">
                                    <input disabled type="radio" class="btn-check" v-model="work.work_status" value="NOT_STARTED" @change="resetStatus" :name="'work-status-' + work.id" :id="'not-done-' + work.id" autocomplete="off">
                                    <label class="btn btn-outline-dark" :for="'not-done-' + work.id">Nije spremna pozicija</label>
                                </template>

                                <template v-if="isWorkNotStartedUser(work)">
                                    <input disabled type="radio" class="btn-check" v-model="work.work_status" value="NOT_STARTED" @change="resetStatus" :name="'work-status-' + work.id" :id="'not-done-' + work.id" autocomplete="off">
                                    <label class="btn btn-outline-secondary" :for="'not-done-' + work.id">Nije započeto</label>
                                </template>

                            </div>
                        </div>

                        <!-- <div class="d-flex w-100 justify-content-between"> -->
                            <!-- <div class="flex-col"> -->
                                <!-- <p class="mb-0"> -->
                                    <!-- Norma: -->
                                    <!-- {{job.work_position.position_unit.normQuantity * work.work_location.type.normatives[job.work_position.id].value}}{{job.work_position.norm_unit}} -->
                                    <!-- </p> -->
                                <!-- <small>Očekivano {{work.work_location.type.normatives[job.work_position.id].value * job.work_position.position_unit.minutesPerUnit}}min</small> -->
                            <!-- </div> -->
                        <!-- </div> -->
                    </a>
                    <!-- <h3 class="mt-3"><i class="fas fa-sticky-note fa-lg me-1" /> Komentar</h3>
                    <h5>Petar Petrovic</h5>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora facilis quae voluptas cumque delectus libero ut officia fugit amet magni?</p> -->



                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{worksInJobStatus=="SUCCESS"?"Zatvori prozor":"Poništi izmene"}}</button>

                    <button v-show="worksInJobStatus=='SUCCESS' && isJobReady(value) && isJobReviewed(value)" type="button" class="btn btn-success" v-on:click="sendReview(value)"><i class="fas fa-wrench fa-clipboard-check me-1" /> Zaključi posao</button>
                    <button v-show="!(worksInJobStatus=='SUCCESS' && isJobReady(value) && isJobReviewed(value))" type="button" class="btn btn-primary" v-on:click="saveWorksEdit()">
                        <span :class="{'d-none': worksInJobStatus != 'SUCCESS'}"><i class="fas fa-check fa-lg me-1" /></span>
                        <div v-if="worksInJobStatus == 'SENDING'" class="spinner-border text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                        </div>
                        Sačuvaj
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery';
import UtilService from '@/service/UtilService';
import {mapState} from 'vuex'
import { Modal } from 'bootstrap';


export default {
    components: {
    },

    props: {
        value: Object,
        focus: Object,
        inputId: {
            type: String,
            default: "",
        }
        
    },


    data() {
        return {
            selectedGroup: -1,
            selectedType: null,
            newTypeName: "",
            locationTypesCopy: {},

            modal: null,
            saveStatus: "",
            submitStatus: "",
            
        }
    },

    computed: {
        ...mapState([
            'worksInJobStatus',
            'user'
        ]),



    },

    methods: {
        getLocationFullindex : UtilService.getLocationFullindex,
        getPositionFullindex : UtilService.getPositionFullindex,
        fullNameList : UtilService.fullNameList,
        isWorkNotReadyStatusUser: UtilService.isWorkNotReadyStatusUser,
        isWorkNotStartedUser: UtilService.isWorkNotStartedUser,

        async saveWorksEdit() {
            this.$store.dispatch('updateWorksInJobReview', {jobAssignmentId: this.value.id, works: this.value.works})
        },
        
        
        isJobReviewed(job) {
            let not_finished;
            not_finished = job.works?.some(function(work) {
                return !(UtilService.isWorkCorrectlyReviewed(work));

            });
            return !not_finished;
                
        },

        isJobReady(job){
            return job.finished_date_time != null;
        },

        loadJobs() {
            this.$store.dispatch('loadActiveJobs', {workerId: this.user.id});
        },

        async sendReview(job) {
            await this.$store.dispatch('submitReview', {jobAssignmentId: job.id});
            this.modal.hide();
            this.loadJobs();
        },

        resetStatus() {
            if (this.worksInJobStatus){
                this.$store.dispatch('resetJobStatus');
            }       
        },

        changeApprove() {
            this.resetStatus();
        },

        changeRejected() {
            this.resetStatus();
        },

        changeNorm() {
            this.resetStatus();
            this.setFinished();
        },

        setFinished() {
            this.value.works.forEach(work => {
                if (work.approved_units >= work.normative_value) work.finished = true;
                else if (work.normative_value > 0) work.finished = false;
            });
        },
    },

    mounted() {
        let modalObject = document.getElementById(this.inputId);
        let _this = this;
        modalObject.addEventListener('hide.bs.modal', function (event) {
            _this.modal._triggerBackdropTransition(event);
            if (_this.value.edited) event.preventDefault();
        })

        this.modal = new Modal(modalObject, {backdrop: 'true'});
    }

}

</script>

<style scoped>

.table > thead:first-child > tr:first-child > .sticky-col {
    position: sticky;
    left: 0;
    background: #fff;}

.table > tbody > tr > .sticky-col {
    position: sticky;
    left: 0;
    background: #fff;
    min-width: 200px;
    border-right: 1px solid rgb(218, 218, 218);
}

.table > tbody > tr > .sticky-col::after {
    content: '';
    position: absolute;
    background: #dee2e6;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
}

.table > thead > tr .type-column{
    cursor: pointer;
}

.table > thead > tr .type-column:hover{
    background: rgb(231, 231, 231);
}

.table > thead > tr .type-column.selected{
    background: rgb(231, 231, 231);
}

.table tr .w-lg {
    min-width: 15em;
}

.table tr .w-md {
    min-width: 7em;
}

.table tr .w-sm {
    min-width: 4em;
}

.approved-units-input {
    width: 90px;
}

</style>