<template>


<div class="container">
    <modal-job-review v-model="selectedJob" inputId="manager-jobs-modal" @updated="jobsUpdate" />
    <modal-worker-job v-model="selectedJob" inputId="worker-jobs-modal" @updated="jobsUpdate" />

    <div class="mb-3 d-flex w-100 justify-content-between">
    <h3>Menadžer: {{user.username}}</h3>
    <button class="btn btn-primary" v-on:click="loadJobs"><i class="fas fa-sync me-1" /></button>
    </div>

    <h6 v-if="allJobsSorted && allJobsSorted.length == 0">Nema aktivnih poslova</h6>
    <div class="accordion" id="jobsAccordion">
        <div v-for="job in allJobsSorted" :key="job.id" class="accordion-item mb-2" :class="{'job-ready': isJobReady(job) || isJobReviewed(job)}">
            <div class="list-group">
                <button class="accordion-button collapsed align-left text-dark" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-job-' + job.id" aria-expanded="false" :aria-controls="'collapse-job-' + job.id">
                    <div class="row w-100">
                        <div class="col-md-8">
                            <span v-for="(name, index) in fullNameList(job.work_position)" :key="index">
                                <span :class="{'badge':true, 'bg-primary':index==0, 'bg-secondary':index!=0 }">
                                    {{name}}
                                </span>
                            </span>
                            <h3><span class="badge bg-primary">{{getPositionFullindex(job.work_position)}}</span> {{job.work_position.position_unit.name}}</h3>
                            <!-- <h5 class="mb-0">Jedinica {{job.work_position.normQuantity}}m</h5> -->
                            <!-- <h5 class="mb-3">Vreme po jedinici {{job.work_position.minutesPerUnit}}min</h5> -->
                            <h6>Započeto: <strong>{{formatDateTime(job.started_date_time)}}</strong></h6>
                            <h6>Završeno: <strong v-if="job.finished_date_time">{{formatDateTime(job.finished_date_time)}}</strong><strong v-else>U toku</strong></h6>                        
                            <h6>Recenzirano: <strong v-if="job.reviewed_date_time">{{formatDateTime(job.reviewed_date_time)}}</strong><strong v-else>/</strong></h6>
                        </div>
                        <div class="col-md-4">
                            <h3 class="mb-0">Poslovođa:</h3>
                            <h6 class="mb-0">{{job.manager.first_name}} {{job.manager.last_name}}</h6>
                            
                            <h3 class="mb-0">Dodeljeno:</h3>
                            <h6 v-for="employee in job.employees" :key="employee.id" class="mb-0">{{employee.first_name}} {{employee.last_name}}</h6>
                        </div>
                    </div>
                </button>


                <div :id="'collapse-job-' + job.id" class="accordion-collapse collapse" aria-labelledby="headingOne">
                    <a v-for="work in job.works" :key="work.id" class="list-group-item list-group-item-action" :class="{'bg-approved': isApproved(work), 'bg-rejected': isRejected(work)}" aria-current="true">
                        <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                            <span class="badge bg-info">{{getLocationFullindex(work.work_location)}}</span>
                            <span class="ms-1" v-for="(name, index) in fullNameList(work.work_location)" :key="index">
                                <span :class="{'badge':true, 'bg-secondary':true }">
                                    {{name}}
                                </span>
                                ❯
                            </span>
                            {{work.work_location.name}}
                            <span v-if="isWorkInProgress(work)">
                                <img src="@/assets/gear-loading.gif" width="30px" height="30px" alt="...">
                            </span>
                        </h5><br>
                        </div>

                        <div class="d-flex w-100 justify-content-between">
                            <div class="flex-col">
                                <!-- <p class="mb-0">
                                    Norma:
                                    {{job.work_position.position_unit.normQuantity * work.work_location.type.normatives[job.work_position.id].value}}{{job.work_position.normUnit}}
                                    </p>
                                <small>Očekivano {{work.work_location.type.normatives[job.work_position.id].value * job.work_position.position_unit.minutesPerUnit}}min</small> -->
                            </div>
                        </div>
                    </a>
                    <div class="col-12">
                        <button :disabled="!isJobReady(job)" class="btn btn-block btn-outline-primary btn-lg mt-2 col-5" data-bs-toggle="modal" data-bs-target="#manager-jobs-modal" v-on:click="openJobsModal(job)"><i class="fas fa-wrench fa-clipboard-list me-1" /> Unos evidencije</button>
                        <button :disabled="!isJobReady(job) || !isJobReviewed(job)" type="button" class="btn btn-block btn-outline-success btn-lg mt-2 col-5" v-on:click="sendReview(job)"><i class="fas fa-wrench fa-clipboard-check me-1" /> Zaključi posao</button>
                        <button class="btn btn-block btn-outline-primary btn-lg mt-2 col-2" data-bs-toggle="modal" data-bs-target="#worker-jobs-modal" v-on:click="openJobsModal(job)"><i class="fas fa-wrench fa-lg me-1" /> Izmeni radove</button>

                    </div>
                </div>

            </div>



        </div>
    </div>

</div>
</template>

<script>
import {mapState} from 'vuex'
import UtilService from '@/service/UtilService.js';
import ModalJobReview from '@/components/modals/ModalJobReview.vue';
import ModalWorkerJob from '@/components/modals/ModalWorkerJob.vue';

export default {
    components: { ModalJobReview, ModalWorkerJob },

    data() {
        return {
            selectedJob: null,
        }
    },
    watch: {
        'worksInJobStatus': function() {
            if (this.worksInJobStatus == "SUCCESS")
                this.loadJobs();
        },
    },
    computed: {
        ...mapState([
            'user',
            'allJobs',
            'worksInJobStatus',
            'allJobsStatus',
        ]),

        allJobsSorted(){
            if (!this.allJobs) return [];

            let jobs = UtilService.deepClone(this.allJobs);
            jobs.sort((a,b) => (a.started_date_time > b.started_date_time ? 1 : -1 ));
            jobs.forEach(job => {
                if (!job.works) return 0;
                job.works.sort((a,b) => (a.id < b.id ? 1 : -1 ));
            });
            return jobs;
        }
    },

    methods: {
        formatDate : UtilService.formatDate,
        formatTime : UtilService.formatTime,
        formatDateTime : UtilService.formatDateTime,

        getLocationFullindex : UtilService.getLocationFullindex,
        getPositionFullindex : UtilService.getPositionFullindex,
        fullNameList : UtilService.fullNameList,

        loadJobs() {
            this.$store.dispatch('loadActiveJobs', {workerId: this.user.id});
        },
        
        isRejected(work){
            return work.workStatus == 'REJECTED'
        },
        
        isApproved(work){
            return work.workStatus == 'APPROVED'
        },
        
        isJobReviewed(job) {
            let not_finished;
            not_finished = job.works?.some(function(work) {
                return !(UtilService.isWorkCorrectlyReviewed(work));

            });
            return !not_finished;
                
        },

        isJobReady(job){
            return job.finished_date_time != null;
        },

        sendReview(job) {
            this.$store.dispatch('submitReview', {jobAssignmentId: job.id}).then(() => {
                this.loadJobs();
            })
        },

        isWorkInProgress(work){
            if (!work) return false;
            return  work.workStatus == "IN_PROGRESS";
        },

        openJobsModal(job) {
            this.$store.dispatch('resetJobStatus');
            job.edited = false;
            this.selectedJob = UtilService.deepClone(job); 
        },
        
        jobsUpdate() {
            this.loadJobs();
        },


    },

    mounted() {
        this.loadJobs();
    }
}
</script>

<style scoped>
    .bg-checked {
        background: rgb(189 255 189);
    }
    
    .bg-not-started {
        background: rgb(230 230 230);
    }

    .bg-approved {
        background: rgb(150 255 150);
    }

    .bg-rejected {
        background: rgb(255 150 150);
    }

    .accordion-item.job-ready .accordion-button {
        background: #ff0;
    }
    .accordion-item.job-ready .accordion-button:hover {
        background: rgb(235, 235, 0);
    }

</style>