<template>
    <div class="modal fade" :id="inputId" tabindex="-1" data-bs-backdrop="static" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div v-if="value && value.works" class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" :id="inputId + 'Label'">
                    Unesi radove
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="discartChanges"></button>
                </div>
                <div class="modal-body">
                    

  
                    <a v-for="work in value.works" :key="work.id" class="list-group-item list-group-item-action" aria-current="true">
                        <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                            <span class="badge bg-info">{{getLocationFullindex(work.work_location)}}</span>
                            <span class="ms-1" v-for="(name, index) in fullNameList(work.work_location)" :key="index">
                                <span :class="{'badge':true, 'bg-secondary':true }">
                                    {{name}}
                                </span>
                                ❯
                            </span>
                            {{work.work_location.name}}
                        </h5>
                        <div class="status-box text-end">
                            <!-- <input type="radio" class="btn-check" v-model="work.workStatus" value="IN_PROGRESS" @change="resetStatus" :name="'work-status-' + work.id" :id="'not-started-' + work.id" autocomplete="off" checked> -->
                            <!-- <label class="btn btn-outline-secondary" :for="'not-started-' + work.id">/</label> -->
                            <input type="radio" class="btn-check" v-model="work.finished_units" :value="-1" @change="resetStatus" :name="'work-status-' + work.id" :id="'not-ready-' + work.id" autocomplete="off">
                            <label class="btn btn-outline-danger btn-sm" :for="'not-ready-' + work.id">Nije spremno</label>

                            <input type="radio" class="btn-check" v-model="work.finished_units" :value="0" @change="resetStatus" :name="'work-status-' + work.id" :id="'not-started-' + work.id" autocomplete="off">
                            <label class="btn btn-outline-secondary btn-sm" :for="'not-started-' + work.id">Nije započeto</label>

                            <!-- Has normative -->
                            <template v-if="work.normative_value">
                                <input type="radio" class="btn-check" :checked="isPartial(work)"  @change="setPartial(work)" :name="'work-status-' + work.id" :id="'started-' + work.id" autocomplete="off">
                                <label class="btn btn-outline-warning btn-sm" :for="'started-' + work.id">Započeto</label>

                                <input type="radio" class="btn-check" v-model="work.finished_units" :value="work.normative_value" @change="setAllFinished(work)" :name="'work-status-' + work.id" :id="'a-done-' + work.id" autocomplete="off">
                                <label class="btn btn-outline-success" :for="'a-done-' + work.id">Urađeno sve</label>
                            </template>
                            <template v-else>
                                <input type="radio" class="btn-check" :checked="isPartial(work)"  @change="setPartial(work)" :name="'work-status-' + work.id" :id="'started-' + work.id" autocomplete="off">
                                <label class="btn btn-outline-warning btn-sm" :for="'started-' + work.id">Započeto</label>

                                <input type="radio" class="btn-check" :checked="isFinished(work)" @change="setAllFinished(work)" :name="'work-status-' + work.id" :id="'all-done-' + work.id" autocomplete="off">
                                <label class="btn btn-outline-success" :for="'all-done-' + work.id">Urađeno sve</label>
                            </template>
                            
                            <template v-if="work.finished_units === '' || work.finished_units > 0">
                                <div class="input-group mb-3 partialy-input-group">
                                    <input type="number" :id="'done-units-' + work.id" v-model="work.finished_units" class="form-control done-units-input" aria-label="Kolicina posla" min="1" :max="work.normative_value" @change="resetStatus">
                                    <span class="input-group-text">/</span>
                                    <input v-if="work.normative_value" type="text" class="form-control" aria-label="Ukupno" :value="work.normative_value" disabled>
                                    <input v-else type="text" class="form-control" aria-label="Ukupno" value="Nije normirano" disabled>
                                    <span class="input-group-text">{{value.work_position.position_unit.norm_unit}}</span>
                                </div>
                            </template>
                        </div>

                        
                        </div>

                        <!-- <div class="d-flex w-100 justify-content-between">
                            <div class="flex-col">
                                <p class="mb-0">
                                    Norma:
                                    {{value.work_position.position_unit.norm_quantity * work.normative_value}}{{value.work_position.norm_unit}}
                                    </p>
                                <small>Očekivano {{work.normative_value * value.work_position.position_unit.minutes_per_unit}}min</small>
                            </div>
                        </div> -->
                    </a>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="discartChanges">{{worksInJobStatus=="SUCCESS"?"Zatvori prozor":"Poništi izmene"}}</button>


                    <template v-if="value.finished_date_time">
                        <button type="button" class="btn btn-primary" v-on:click="saveWorksEdit()">
                            <span :class="{'d-none': worksInJobStatus != 'SUCCESS'}"><i class="fas fa-check fa-lg me-1" /></span>
                            <div v-if="worksInJobStatus == 'SENDING'" class="spinner-border text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            Ažuriraj
                        </button>
                    </template>
                    <template v-else>
                        <button v-show="(isSubmitable() && worksInJobStatus != 'SENDING')" :disabled="!isJobCompled(value)" type="button" class="btn btn-block btn-outline-success btn-lg" v-on:click="finishJob(value)"><i class="fas fa-wrench fa-clipboard-check me-1" /> Završi posao</button>

                        <button v-show="!(isSubmitable() && worksInJobStatus != 'SENDING')" type="button" class="btn btn-primary" v-on:click="saveWorksEdit()">
                            <span :class="{'d-none': worksInJobStatus != 'SUCCESS'}"><i class="fas fa-check fa-lg me-1" /></span>
                            <div v-if="worksInJobStatus == 'SENDING'" class="spinner-border text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            Sačuvaj
                        </button>
                    </template>                    

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery';
import UtilService from '@/service/UtilService';
import {mapState} from 'vuex'
import { Modal } from 'bootstrap';

export default {
    components: {
    },

    props: {
        value: Object,
        focus: Object,
        inputId: {
            type: String,
            default: "",
        }
        
    },


    data() {
        return {
            selectedGroup: -1,
            selectedType: null,
            newTypeName: "",
            locationTypesCopy: {},
            modal: null,
            test:false,
            
        }
    },

    computed: {
        ...mapState([
            'worksInJobStatus',
            'user'
        ]),



    },

    methods: {
        getLocationFullindex : UtilService.getLocationFullindex,
        getPositionFullindex : UtilService.getPositionFullindex,
        fullNameList : UtilService.fullNameList,

        discartChanges() {
            this.value.edited = false;
            this.modal.hide();
        },

        saveWorksEdit() {
            this.setFinished();
            this.$store.dispatch('updateWorksInJob', {jobAssignmentId: this.value.id, works: this.value.works}).then(() => {
                this.$emit('updated', '')
            })
            this.value.edited = false;
        },


        setFinished() {
            this.value.works.forEach(work => {
                if (work.normative_value == work.finished_units) work.finished = true;
                else if (work.normative_value > 0) work.finished = false;
            });
        },

        resetStatus() {
            this.value.edited = true;
            if (this.worksInJobStatus){
                this.$store.dispatch('resetJobStatus');
            }
        },


        isSubmitable() {
            if (this.isJobCompled(this.value) && (this.worksInJobStatus == 'SUCCESS' || !this.value.edited)) return true;
            return false;
        },

        
        loadJobs() {
            this.$store.dispatch('loadWorkerJobs', {workerId: this.user.id});
        },

        isJobCompled(job) {
            let not_finished;
            not_finished = job.works?.some(function(work) {
                return !UtilService.isWorkFinishedUser(work);
            });
            
            return !not_finished;
                
        },

        finishJob(job) {
            this.$store.dispatch('submitJobForReview', {jobAssignmentId: job.id}).then(() => {
                this.modal.hide();
                this.loadJobs();
                this.$emit('updated', '')

            });
        },
        
        isPartial(work) {

            if (work.normative_value){
                if (work.finished_units === "") return true;
                if (work.finished_units > 0 && work.finished_units != work.normative_value) return true;
                return false;
            } else {
                if (work.finished_units > 0 && work.finished == false) return true;
                return false;
            }
        },

        isFinished(work) {
            if (!work.normative_value) {
                if (work.finished_units > 0 && work.finished == true) return true;
                return false;
            }
        },

        setPartial(work) {
            work.finished = false;

            if (!(work.finished_units > 0)) {
                work.finished_units = 1;
            }

            this.resetStatus();
        },

        setAllFinished(work) {
            work.finished = true;
            if (!this.normative_value && !(work.finished_units > 0)) {
                work.finished_units = 1;
            }

            this.resetStatus();
        }

    },

    mounted() {
        let modalObject = document.getElementById(this.inputId);
        let _this = this;
        modalObject.addEventListener('hide.bs.modal', function (event) {
            _this.modal._triggerBackdropTransition(event);
            if (_this.value.edited) event.preventDefault();
        })

        this.modal = new Modal(modalObject, {backdrop: 'true'});
        console.log(this.modal);
    }

}

</script>

<style scoped>

.table > thead:first-child > tr:first-child > .sticky-col {
    position: sticky;
    left: 0;
    background: #fff;}

.table > tbody > tr > .sticky-col {
    position: sticky;
    left: 0;
    background: #fff;
    min-width: 200px;
    border-right: 1px solid rgb(218, 218, 218);
}

.table > tbody > tr > .sticky-col::after {
    content: '';
    position: absolute;
    background: #dee2e6;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
}

.table > thead > tr .type-column{
    cursor: pointer;
}

.table > thead > tr .type-column:hover{
    background: rgb(231, 231, 231);
}

.table > thead > tr .type-column.selected{
    background: rgb(231, 231, 231);
}

.table tr .w-lg {
    min-width: 15em;
}

.table tr .w-md {
    min-width: 7em;
}

.table tr .w-sm {
    min-width: 4em;
}

.partialy-input-group > input {
    width: 100px;
}

.partialy-input-group > .done-units-input {
    background: #ffcf41;
    border: 3px solid orange;
}

</style>